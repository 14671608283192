import _pad from "./pad.js";
var exports = {};
var pad = _pad;
var env = typeof window === "object" ? window : self;
var globalCount = Object.keys(env).length;
var mimeTypesLength = navigator.mimeTypes ? navigator.mimeTypes.length : 0;
var clientId = pad((mimeTypesLength + navigator.userAgent.length).toString(36) + globalCount.toString(36), 4);

exports = function fingerprint() {
  return clientId;
};

export default exports;